import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.Files.length > 0
    ? _c(
        VContainer,
        {
          staticClass: "dxa-briefing-card mt-4",
          attrs: { fluid: "", tag: "section" },
        },
        [
          _c("h4", { staticClass: "dxa-briefing-section-header h4" }, [
            _vm._v(" " + _vm._s(_vm.$t("files")) + " "),
          ]),
          _c(
            VRow,
            { staticClass: "mt-4" },
            _vm._l(_vm.Files, function (item) {
              return _c(
                VCol,
                { key: item.id, attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "file-div",
                      class: {
                        blur:
                          _vm.loggedUser.type === _vm.UserTypeEnum.Investor &&
                          !_vm.SignedNDA,
                      },
                    },
                    [
                      _c("p", { staticClass: "file-div-name" }, [
                        _vm._v(_vm._s(item.fileName)),
                      ]),
                      _c(VSpacer),
                      _vm.fileDownloading !== item
                        ? _c(
                            VIcon,
                            {
                              staticClass: "mr-4",
                              attrs: { color: "secondary" },
                              on: {
                                click: function ($event) {
                                  return _vm.downloadFile(item)
                                },
                              },
                            },
                            [_vm._v("mdi-download")]
                          )
                        : _vm._e(),
                      _vm.fileDownloading == item
                        ? _c(VProgressCircular, {
                            staticClass: "mr-4",
                            attrs: {
                              color: "secondary",
                              size: "16",
                              indeterminate: "",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }